import "./ModalSuccessMsg.style.scss";

const ModalSuccessMsg = (props) => {
  let { isVisible, onClickClose, titleMsg, detailMsg } = props;

  return (
    <div
      style={{
        display: isVisible ? "flex" : "none",
      }}
      className="flex-column modal_container"
    >
      <div className="flex-end">
        <div onClick={onClickClose} className="pointer_cursor">
          <img
            // loading="lazy"
            src="/assets/images/icon/ico_delete_full.png"
            alt="Icon Delete"
            className="contain_image img_delete_modal"
          />
        </div>
      </div>

      <div className="flex-center-column">
        <img
          // loading="lazy"
          src="/assets/images/icon/ico_tick_success.png"
          alt="Icon Successful"
          className="contain_image icon_sucess_msg"
        />
        <div className="font_news_bold text-align-center modal_msg fz-15-mobile fz-18-75 mg-bottom-1 pd-bottom-1 mg-top-15">
          {titleMsg}
        </div>

        <div className="mg-bottom-0-5 col-80 col-100-mobile text-align-center fz-1-mobile fz-13-75">
          {detailMsg ||
            `Sunday English sẽ liên hệ lại bạn trong thời gian sớm nhất. Trân trọng
          cảm ơn!`}
        </div>

        {/* <div className="color-red fz-1-mobile fz-12-5 text-align-center">
          Hotline hỗ trợ:{" "}
          <a
            href="tel: 02462813888"
            className="white_space_pre color-red text-align-center font_news_bold fz-12-mobile fz-15 font_news_bold"
          >
            024 6281 3888
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ModalSuccessMsg;
