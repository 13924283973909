import "./ScrollFixed.style.scss";
import { scrollFixedLogic } from "./ScrollFixed.logic";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";

const ScrollFixed = () => {
  let { handleScrollTop, handleNavigate } = scrollFixedLogic();
  const urlParams = new URLSearchParams(window.location.search);
  const mds = urlParams.get('mds');

  return (
    <>
      <div className="box-right_fixed">
        <div className="enable-mobile" onClick={() => handleNavigate('/'+TypeHeaderNewsItem.HOME+'?mds='+mds)}>
          <img
            src="/assets/images/homeNews/icon/icon_text_hs.png"
            alt="Icon phu huynh"
            className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
          />
        </div>
        <div onClick={handleScrollTop}>
          <img
            src="/assets/images/homeNews/icon/ico_scroll_up.png"
            alt="Icon Scroll Up"
            className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
          />
        </div>
      </div>
    <div className="enable-mobile">
      <div className="box-left_fixed">
        <div onClick={() => handleNavigate('/'+TypeHeaderNewsItem.PARENT+'?mds='+mds)}>
          <img
            src="/assets/images/homeNews/icon/icon_text_ph.png"
            alt="Icon phu huynh"
            className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
          />
        </div>
        <div onClick={() => handleNavigate('/'+TypeHeaderNewsItem.TEACHER+'?mds='+mds)}>
          <img
            src="/assets/images/homeNews/icon/icon_text_gv.png"
            alt="Icon giao vien"
            className="icon_scale icon_scroll_fixed"
          />
        </div>
      </div>
    </div>
    </>
  );
};
export default ScrollFixed;
