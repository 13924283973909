import "./FooterNews.style.scss";
import { footerLogic } from "./FooterNews.logic";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import classNames from "classnames";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { FooterSelection } from "../../_constants/footerSelection";
import { LinkApp } from "../../_constants/linkDownloadApp";
import { hasDomainStore } from "../../_base/Validate";
import LazyLoad from "react-lazyload";
import { handleNavigateCurriculumDownload } from "../../_base/FunctionBase";
import { Fragment } from "react";
import { history } from "../../_helpers";
import {Mydata} from '../../_base/Mydata'

const FooterNews = (props) => {
  let {
    dataFooterMobile,
    dataSupport,
    dataCourses,
    dataBuyCoursesTeacher,
    dataBuyCourses,
    dataPolicy,
    dataTariff,
    handleNavigateFooter,
    handleOpenOption,
    isOpen,
    dataInforDownload,
    handleOpenLinkApp,
  } = footerLogic(props);

  const renderFooterItem = (item, type) => {
    return (
      <Fragment key={item.id}>
        {item.typeStore == "store" && hasDomainStore() ? null : (
          <HashLink
            scroll={(ele) => {
              if(type === FooterSelection.POLICY) {
                window.scrollTo({top: 0, behavior: 'instant'})
              } else {
                ele.scrollIntoView({ behavior: "instant" })
              }
            }}
            elementId={item?.eleId}
            key={item.id}
            to={`/${item?.href}`}
            onClick={() => {
              item?.href?.includes("faq") &&
                localStorage.removeItem("categoryFAQ");
            }}
            className={`mg-vertical-0-5 pd-left-4per color_hover color-light-black footer_detail_item fz-08-mobile mg-bottom-0-5 animation_slidein_top ${
              isOpen == type ? "display_flex_mobile" : "display_none_mobile"
            } ${type == FooterSelection.POLICY && " mg-bottom-1-mobile"}`}
          >
            {item.name}
          </HashLink>
        )}
      </Fragment>
    );
  };
  const renderFooterItemMobile = (item, type) => {
    return (
      <HashLink
        scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
        key={item.id}
        to={`/${item?.href + "_mb"}`}
        className={`mg-vertical-0-5 pd-left-4per color_hover color-light-black fz-08 mg-bottom-0-5 animation_slidein_top ${
          isOpen == type ? "display_flex_mobile" : "display_none_mobile"
        } ${type == FooterSelection.POLICY && " mg-bottom-1-mobile"}`}
      >
        {item.name}
      </HashLink>
    );
  };

  const renderListFooter = (item, type) => {
    return (
      <div
        className={`pd-left-4per flex-column list_option_right animation_slidein_top ${
          isOpen == type ? "display_flex_mobile" : "display_none_mobile"
        }`}
        key={item.id}
      >
        <div className="fz-09-mobile font_news_bold">{item.name}</div>

        <div className="flex-column mg-top-0-5">
          {item.data.map((item) => renderFooterItem(item, type))}
        </div>
      </div>
    );
  };

  // Render Ico Download
  const renderInforDownload = (item) => {
    return (
      <div key={item.id} className="flex-column item_infor_download">
        <div className="flex-align item_infor_title">
          <img
            src="/assets/images/icon/ico_tick.png"
            alt="Icon Tick"
            className="contain_image img_tick_title display_none_mobile"
          />
          <span className=" font_news_bold">{item.title}</span>
        </div>

        <div className="flex-align img_download_container">
          <div
            onClick={() =>
              handleNavigateCurriculumDownload(
                item.type == "teacher"
                  ? "teacher"
                  : item.type == "parent"
                  ? "parent"
                  : "student"
              )
            }
            className="icon_qr flex-center"
            style={{
              cursor: "pointer",
            }}
          >
            <img
              src={`/assets/images/logo_se_${item.type}.png`}
              alt="Icon Logo SE"
              className=" contain_image"
            />
          </div>

          <div className="footer_app_download_container ">
            <div className="flex-center img_app_download_container">
              <img
                src="/assets/images/homeNews/icon/ico_appstore.png"
                alt="Icon App Store"
                className="contain_image img_app_download"
                style={{
                  marginBottom: "0.8rem",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenLinkApp(LinkApp[`${item.type}`].APP_STORE)
                }
              />
            </div>

            <div className="flex-center img_app_download_container">
              <img
                src="/assets/images/homeNews/icon/ico_ggplay.png"
                alt="Icon GG Play"
                className="contain_image img_app_download "
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenLinkApp(LinkApp[`${item.type}`].GG_PLAY)
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="footer_wrapper flex-center-column">
      <div
        className="footer_container"
        style={{
          paddingBottom: props?.type == "download" && "3.5rem",
        }}
      >
        <div className="flex-column  mg-right-25 footer_left_container">
          <div className="footer_content_divider">
            {/* <div className="hide display_block_mobile">
              <div
                onClick={() => handleOpenOption(FooterSelection.INTRODUCTION)}
                className={`font_news_bold flex-jus-between-mobile mg-bottom-0-mobile title_footer_container mg-bottom-0-7 ${
                  isOpen == FooterSelection.INTRODUCTION
                    ? " font_news_bold_mobile"
                    : "font_news_mobile"
                }`}
                // style={{
                //   fontWeight: isOpen == FooterSelection.INTRODUCTION && "700",
                // }}
              >
                <HashLink
                  scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
                  to={`/${TypeHeaderNewsItem.INTRODUCTION}`}
                  className={`font_news_bold  title_footer ${
                    isOpen == FooterSelection.INTRODUCTION
                      ? " font_news_bold_mobile"
                      : " font_news_mobile"
                  }`}
                >
                  Giới thiệu
                </HashLink>
                <div className=" cursor_pointer hide  display_flex_mobile">
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 26 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames({
                      rotate_180: isOpen == FooterSelection.INTRODUCTION,
                    })}
                    style={{ transition: "all 0.3s" }}
                  >
                    <path
                      d="M13.2809 15.704L0.507935 2.93097L2.74695 0.692017L13.2611 11.206L23.7389 0.539978L25.9979 2.75897L13.2809 15.704Z"
                      fill="#4D4D4D"
                    />
                  </svg>
                </div>
              </div>
              <div
                className={`pd-left-4per mg-bottom-0-7 mg-bottom-0-7-mobile animation_slidein_top ${
                  isOpen == FooterSelection.INTRODUCTION
                    ? "display_flex_mobile flex-column-mobile"
                    : "display_none_mobile"
                }`}
                // onClick={() => handleNavigateFooter("introduction/#about")}
              >
                <HashLink
                  scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
                  to="/introduction/#about"
                >
                  <div className=" color_hover color-light-black fz-09-mobile footer_detail_item">
                    Về Sunday English
                  </div>
                </HashLink>
              </div>

              <div
                className={`pd-left-4per mg-bottom-0-7 mg-bottom-0-7-mobile animation_slidein_top ${
                  isOpen == FooterSelection.INTRODUCTION
                    ? "display_flex_mobile flex-column-mobile"
                    : "display_none_mobile"
                }`}
              >
                <HashLink
                  scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
                  to="/introduction/#our_missions"
                >
                  <div className=" color_hover color-light-black fz-09-mobile footer_detail_item">
                    Sứ mệnh của chúng tôi
                  </div>
                </HashLink>
              </div>
            </div> */}

            <div className="pd-top-1 border-top-light-1-mobile pd-left-4per flex-column ">
              <div className="flex-jus-end-between flex-column-mobile ">
                <div className=" flex-column flex-1 col-60 col-max-100-mobile one-line col-100-mobile ">
                  {/* <div className="flex-align mg-bottom-1">
                    <img
                      src="/assets/images/homeNews/icon/ico_msg.png"
                      alt="Icon Message"
                      className="mg-right-0-5  icon_contact"
                    />

                    <div className="color-light-black fz-08-mobile fz-09">
                      <HashLink to={`/${TypeHeaderNewsItem.TEACHER}`}>
                        Gửi tin nhắn
                      </HashLink>
                    </div>
                  </div> */}
                  {/* <div className="font_news_bold font_news_mobile mg-bottom-0-9 mg-bottom-1-mobile color-light-black fz-1-mobile text_title_contact">
                    Liên hệ
                  </div> */}
                  <div className="flex-align mg-bottom-1 mg-bottom-0-5-mobile">
                    {/* <div className="flex-align footer_contact_box">
                      <img
                        src="/assets/images/homeNews/icon/ico_phone.png"
                        alt="Icon Phone"
                        className="mg-right-0-5 icon_contact"
                      />

                      <div className="flex-column ">
                        <a
                          href="tel:02462813888"
                          className="fz-15-mobile text_phone_number one-line font_news_semi_bold color-red"
                        >
                          0762 007 298
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <div className="flex-align footer_email_social flex-jus-between mg-bottom-15 col-100">
                    {/* <div className="flex-align mg-bottom-0-7-mobile footer_email_contact footer_contact_box">
                      <img
                        src="/assets/images/homeNews/icon/ico_mail.png"
                        alt="Icon Mail"
                        className="mg-right-0-5 icon_contact"
                      />

                      <div className="flex-column one-line">
                        <a
                          href="mailto:support@sundayenglish.com"
                          className="text_underline color-light-black  one-line  font_news_semi_bold fz-08-mobile text_email"
                        >
                          support@sundayenglish.com
                        </a>
                      </div>
                    </div> */}
                    <div className="flex-align icon_social_container hide display_flex_mobile">
                      <a href="#">
                        <img
                          src="/assets/images/homeNews/icon/ico_fb.png"
                          alt="Icon Facebook"
                          className="icon_social"
                        />
                      </a>
                      <a href="#">
                        <img
                          src="/assets/images/homeNews/icon/ico_zalo.png"
                          alt="Icon Zalo"
                          className="icon_social"
                        />
                      </a>
                      <a href="#">
                        <img
                          src="/assets/images/homeNews/icon/ico_ytb.png"
                          alt="Icon Youtube"
                          className="icon_social"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="display_none_mobile flex-align icon_social_container col-100-mobile">
                    <a href="#">
                      <img
                        src="/assets/images/homeNews/icon/ico_fb.png"
                        alt="Icon Facebook"
                        className="icon_social"
                      />
                    </a>
                    <a href="#">
                      <img
                        src="/assets/images/homeNews/icon/ico_zalo.png"
                        alt="Icon Zalo"
                        className="icon_social"
                      />
                    </a>
                    <a href="#">
                      <img
                        src="/assets/images/homeNews/icon/ico_ytb.png"
                        alt="Icon Youtube"
                        className="icon_social"
                      />
                    </a>
                  </div>
                </div>

                {props?.type != "download" && (
                  <div
                    className="flex-column hide display_flex_mobile footer_social_container col-40 col-100-mobile col-max-100-mobile "
                    style={{
                      marginLeft: "0.5rem",
                    }}
                  >
                    {dataInforDownload.map((item) => renderInforDownload(item))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pd-left-4per footer_1_tail flex-column fz-08">
            <div className="footer_detail_item fz-09-mobile black-color-mobile">
              Công ty Cổ phần Công nghệ Giáo dục GK
            </div>
            {/* <div className="fz-09 fz-09-mobile black-color-mobile">
              MST:00000000 do Sở KH và ĐT TP.Hà Nội cấp ngày 00/00/0000
            </div>
            <div className="fz-09 fz-09-mobile black-color-mobile">
              Người đại diện: Ông Ngô Viết Tuấn
            </div> */}
            <div className="footer_detail_item fz-09-mobile black-color-mobile">
              Tầng 5, Tòa nhà 141 Hoàng Quốc Việt, phường Nghĩa Đô, quận Cầu
              Giấy, Tp. Hà Nội, Việt Nam.
            </div>
          </div>
        </div>

        <div className="footer_right_container">

        
            <div className="flex-align display_none_mobile footer_social_container col-100 col-100-mobile col-max-100-mobile ">
              {dataInforDownload.map((item) => renderInforDownload(item))}
            </div>
          
          <div className="flex-jus-start display_none_mobile" style={{marginTop: '32px'}}>
            <LazyLoad once style={{display: 'flex', justifyContent: 'center'}}>
              <img
                src="/assets/images/homeNews/icon/img_bct.png"
                alt="Image Bo Cong Thuong"
                style={{
                  paddingTop: "1.8rem",
                  width: "48%",
                  minWidth: "8rem",
                  maxWidth: "12rem",
                }}
              />
            </LazyLoad>
          </div>
        </div>
      </div>

      <div className="hide display_flex_mobile">
        <img
          src="/assets/images/homeNews/icon/img_bct.png"
          alt="Image Bo Cong Thuong"
          className="img_bct contain_image"
        />
      </div>
      <div
        className="color-light-black text_license fz-09-mobile color-light-text-mobile text-align-center "
        style={{
          fontSize: "0.92rem",
        }}
      >
        {`Copyright @ 2007-${new Date().getFullYear()} GK Corp. All Rights Reserved`}
        {/* <div className="version-app">{Mydata.version}</div> */}
      </div>
    </div>
  );
};

export default FooterNews;
