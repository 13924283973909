import React from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";

function AlertError(props) {
  let { changePasswordError, imgErorr, errorHelpClassName, isShowPopup, hasBtnClosePopup, onClosePopup, textComplete, textClose } = props;
  const dispatch = useDispatch();

  // let autoClose = setTimeout(() => {
  //   isShowPopup && close();
  // }, 2000);

  function complete() {
      // clearTimeout(autoClose);
      props?.onComplete && props.onComplete();
      dispatch(alertActions.clear());
  }

  const handleClosePopup = () => {
    props?.onClosePopup && props.onClosePopup();
    dispatch(alertActions.clear());
  }

  if (props.notShowError) {
    return null;
  } else if(isShowPopup) {
    return (
      <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <p className="text-center" style={{whiteSpace: 'pre-line'}}>{props.message}</p>
              <div className="form-sunE-button">
                  {hasBtnClosePopup && <button className="btn-default mr-20" style={{width: 'unset', padding: '0 24px'}} onClick={handleClosePopup}>{textClose || 'Đóng'}</button>}
                  <button className="btn-line-blue" onClick={complete}>{textComplete || 'Đóng'}</button>
              </div>
          </div>
      </div>
    )
  } else {
    return (
      <div className="center-flex">
        <div className={`error-help flex-m ${errorHelpClassName}`}>
          {/* {imgErorr && (
            <div className="">
              <img src="/assets/images/ico_danger.png" className="error-img" />
            </div>
          )} */}
          <div className="flex-1">
            {changePasswordError ? (
              <p className="bold" style={{ fontSize: "18px" }}>
                Mật khẩu bạn nhập không trùng khớp.
              </p>
            ) : (
              <p className="bold" style={{ fontSize: "18px" }}>
                {props.message}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { AlertError };
